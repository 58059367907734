const Address = {
  nft: {
    1: "0x7B60ED9eff9FeA114058e2aE66f799b4C5b6aF4a",
    4: "0x7B60ED9eff9FeA114058e2aE66f799b4C5b6aF4a",
  },
  staking: {
    1: "0xe2Bb7827D4Fa18b37A0C62bEB06d76F51D5bE15C",
    4: "0xe2Bb7827D4Fa18b37A0C62bEB06d76F51D5bE15C",
  },
};

export default Address;
